import { useNavigate, useParams } from 'react-router-dom';
import categoryOpenStyles from '../cssFolder/CategoryOpen.module.css';
import addButtonIcon from '../images/addButtonIcon.svg';
import goBackIcon from '../images/goBackIcon.svg';
import { useEffect, useState } from 'react';
import { URL } from '../utils/url';
import { useDispatch } from 'react-redux';
import { addProduct } from '../redux/cartSlice';
import { v4 as uuidv4 } from 'uuid';

function CategoryOpen() {
  const navigate = useNavigate();
  const [item, setItem] = useState({});
  const [ingredients, setIngredients] = useState([]);
  const [types, setTypes] = useState([]);
  const { productID } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    // get item information
    fetch(`${URL}/items/get-item/${productID}`)
      .then((res) => res.json())
      .then((data) => setItem(data.item));

    // get ingredients for current item
    fetch(`${URL}/ingredients/get-ingredients-for-current-item/${productID}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.ingredients) {
          setIngredients(
            data.ingredients.map((ingredient) => {
              return { ...ingredient, selected: ingredient.price ? false : true };
            }),
          );
        }
      });

    // get types for current item
    fetch(`${URL}/types/get-types-for-current-item/${productID}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.types) {
          setTypes(
            data.types.map((type) => {
              return { ...type, active: type.key1 };
            }),
          );
        }
      });
  }, [productID]);

  const getProductPriceWithIngredients = () => {
    return +ingredients.reduce((accm, current) => {
      if (current.selected) {
        accm = accm + +current.price;
      }
      return accm;
    }, +item.price);
  };

  const handleAddProductToCart = () => {
    const newProduct = {
      ...item,
      price: ingredients.reduce((aggr, current) => {
        if (current.selected && current.price) {
          aggr += +current.price;
        }
        return +aggr;
      }, +item.price),
      orderId: uuidv4(),
      status: 'active',
      ingredients: ingredients
        .filter((ing) => ing.selected)
        .map((ing) => {
          return { id: ing.id, name: ing.name };
        }),
      types: types.map((type) => {
        return { id: type.id, value: type.active };
      }),
    };
    dispatch(addProduct(newProduct));
  };

  return (
    <div className={categoryOpenStyles.categoryOpen}>
      <div className={categoryOpenStyles.divForGoBackIconStyle}>
        <img onClick={() => navigate(-1)} src={goBackIcon} alt="" />
      </div>

      <div className={categoryOpenStyles.productOpen}>
        <img
          className={categoryOpenStyles.categoryImage}
          style={{ objectFit: 'contain' }}
          src={`${URL}/${item.image}`}
          alt=""
        />
        <div className={categoryOpenStyles.productDetails}>
          <p className={categoryOpenStyles.productName}>{item.name}</p>
          <p className={categoryOpenStyles.productPrice}>{item.price} amd</p>
        </div>
        <p className={categoryOpenStyles.productDescription}>{item.description}</p>
        <div
          style={{
            display: 'flex',
            gap: '12px',
          }}>
          {types.map((type) => {
            return (
              <div className={categoryOpenStyles.divForItemTypes} key={type.id}>
                <div className={categoryOpenStyles.itemTypes}>
                  <p
                    onClick={() => {
                      setTypes(
                        types.map((t) => {
                          if (t.id === type.id) {
                            return { ...t, active: t.key1 };
                          }
                          return t;
                        }),
                      );
                    }}
                    className={categoryOpenStyles.hot}>
                    {type.key1}
                  </p>
                  <p
                    onClick={() => {
                      setTypes(
                        types.map((t) => {
                          if (t.id === type.id) {
                            return { ...t, active: t.key2 };
                          }
                          return t;
                        }),
                      );
                    }}
                    className={categoryOpenStyles.cold}>
                    {type.key2}
                  </p>
                </div>
                <div
                  className={
                    type.active === type.key2
                      ? categoryOpenStyles.circleForTypesRight
                      : categoryOpenStyles.circleForTypesLeft
                  }>
                  <p>{type.active}</p>
                </div>
              </div>
            );
          })}
        </div>
        <div
          className={categoryOpenStyles.divForitemIngredients}
          style={{
            height: ingredients.length ? '80px' : 'auto',
            overflowY: ingredients.length ? 'scroll' : 'auto',
          }}>
          {ingredients
            .filter((ingredient) => !ingredient.price)
            .map((ingredient) => {
              return (
                <div
                  key={ingredient.id}
                  style={{
                    width: '30%',
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '7px',
                  }}>
                  <input
                    onChange={() => {
                      setIngredients(
                        ingredients.map((ing) => {
                          if (ing.id === ingredient.id) {
                            return { ...ing, selected: !ing.selected };
                          }
                          return ing;
                        }),
                      );
                    }}
                    type="checkbox"
                    checked={ingredient.selected}
                    style={{ accentColor: '#42715d' }}
                  />
                  <p>{ingredient.name}</p>
                </div>
              );
            })}
        </div>
        <div className={categoryOpenStyles.divForBillableIngredients}>
          {ingredients
            .filter((ingredient) => ingredient.price)
            .map((ingredient) => {
              return (
                <div
                  onClick={() => {
                    setIngredients(
                      ingredients.map((ing) => {
                        if (ing.id === ingredient.id) {
                          return { ...ing, selected: !ing.selected };
                        }
                        return ing;
                      }),
                    );
                  }}
                  style={{
                    border: ingredient.selected ? '1px solid green' : '',
                  }}
                  key={ingredient.id}
                  className={categoryOpenStyles.billableIngrds}>
                  <p className={categoryOpenStyles.ingredientName}>{ingredient.name}</p>
                  <span className={categoryOpenStyles.priceWithCurrency}>
                    <p className={categoryOpenStyles.ingrdPrice}>{ingredient.price}</p>
                    <p className={categoryOpenStyles.currency}>amd</p>
                  </span>
                </div>
              );
            })}
        </div>
        <div
          onClick={handleAddProductToCart}
          className={categoryOpenStyles.divForAddItemWithButton}>
          <p className={categoryOpenStyles.itemPrice}>{getProductPriceWithIngredients()} amd</p>
          <div className={categoryOpenStyles.addingItemButton}>
            <p className={categoryOpenStyles.itemAddButton}>Add</p>
            <img src={addButtonIcon} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategoryOpen;
